import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { gsap } from "gsap"

import { usePageTransitionContext } from "../context/PageTransitionContext"

type ListItem = {
  label: string
  url: string | null
}

type Props = {
  list: ListItem[]
  id: string
}

const Container = styled.div`
  padding: 280px 100px 100px;
`

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(1, auto);
  grid-row-gap: 10px;
  grid-column-gap: 30px;
  justify-content: center;
  margin: 0 auto;
  width: 1500px;
  max-width: 100%;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, auto);
  }

  @media (min-width: 1200px) {
    grid-column-gap: 30px;
    justify-content: space-between;
    grid-template-columns: repeat(3, auto);
  }
`

const ListCell = styled.div`
  opacity: 0;
  transform: translateY(50px);
  text-align: center;
`

const Link = styled.a`
  position: relative;
  font-family: "Minion Pro";
  font-size: 20pt;
  line-height: 22pt;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-weight: 500;
  display: inline;

  img {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all ease 200ms;
    width: 80%;
    height: 10px;
    z-index: 2;
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    display: none;
  }

  &:hover {
    img {
      clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
    }
  }

  @media (min-width: 768px) {
    img {
      display: block;
    }
  }

  @media (min-width: 900px) and (max-width: 1380px) {
    font-size: 15pt;
    line-height: 17pt;
  }

  @media (min-width: 768px) and (max-width: 900px) {
    font-size: 13pt;
    line-height: 15pt;
  }

  @media (min-width: 900px) and (max-width: 1200px) {
    font-size: 17pt;
    line-height: 19pt;
  }
`

const ListSection = ({ list, id }: Props): JSX.Element => {
  const { entering, exiting } = usePageTransitionContext()
  const listRef = useRef<HTMLDivElement | null>(null)
  const loaded = useRef(false)

  useEffect(() => {
    if (listRef.current) {
      if (entering) {
        gsap.set(".list-cell", {
          opacity: 0,
          y: 50,
        })
        listRef.current
          .querySelectorAll(".list-cell")
          ?.forEach((element, index) => {
            gsap.to(element, {
              opacity: 1,
              y: 0,
              duration: 0.3,
              delay: 0.015 * index,
              ease: "power3.in",
            })
          })
        loaded.current = true
      }

      if (exiting && loaded.current) {
        listRef.current
          .querySelectorAll(".list-cell")
          ?.forEach((element, index) => {
            gsap.to(element, {
              opacity: 0,
              y: -50,
              duration: 0.3,
              delay: 0.015 * index,
              ease: "power3.inOut",
            })
          })
      }
    }
  }, [entering, exiting, id])

  return (
    <Container>
      <List ref={listRef}>
        {list.map((list, index) => (
          <ListCell key={`list-${id}-item-${index}`} className={"list-cell"}>
              <Link href={list.url || undefined} target="_blank" rel="noopener noreferrer">
                {list.label}
                <img src="/images/line.svg" />
              </Link>
          </ListCell>
        ))}
      </List>
    </Container>
  )
}

export default React.memo(ListSection)
