import { useEffect } from "react"
import { gsap } from "gsap"

export const useSkewOnScroll = (
  containerRef: React.MutableRefObject<null | HTMLElement>
): void => {
  useEffect(() => {
    const onSmoothScroll = (e: any) => {
      const skew = (e.detail.current.y - e.detail.current.endY) / 50

      gsap.set(containerRef.current, {
        skewY: Math.min(2, Math.max(skew, -2)),
      })
    }
    window.addEventListener("smoothscroll", onSmoothScroll)

    return () => {
      window.removeEventListener("smoothscroll", onSmoothScroll)
    }
  }, [])
}
