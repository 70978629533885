const getLastNames = (full_name: string): string => {
  return full_name.substr(full_name.indexOf(" ") + 1).toLowerCase()
}

interface Item {
  label: string
}

export const sortByName = (a: Item, b: Item): number => {
  return (
    getLastNames(a.label).localeCompare(getLastNames(b.label)) ||
    a.label.localeCompare(b.label)
  )
}
