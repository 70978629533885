import React, { useRef } from "react"

import ListSection from "../components/ListSection"
import SEO from "../components/seo"
import { useSkewOnScroll } from "../hooks/scroll"
import { useTranslation } from "react-i18next"
import { sortByName } from "../utils/text"

const SCREENWRITERS = [
  {
    label: "Benjamin Charbit",
    url: "https://www.imdb.com/name/nm2224444/?ref_=fn_al_nm_1",
  },
  {
    label: "Laure Chichmanov",
    url: "https://www.imdb.com/name/nm4281173/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Jérôme Fansten",
    url: "https://www.imdb.com/name/nm8782638/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Maud Konan",
    url:
      "http://www.agencesartistiques.com/Fiche-Artiste/736132-maud-konan.html",
  },
  {
    label: "Olivier Nicklaus",
    url: "https://www.imdb.com/name/nm1367646/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Pierre Puget",
    url:
      "http://www.agencesartistiques.com/Fiche-Artiste/728253-pierre-puget.html",
  },
  {
    label: "Victor Rodenbach",
    url: "https://www.imdb.com/name/nm5506336/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Thomas Bidegain",
    url: "https://www.imdb.com/name/nm0081179/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Thomas Colineau",
    url:
      "http://www.agencesartistiques.com/Fiche-Artiste/707552-thomas-colineau.html",
  },
  {
    label: "Théo Courtial",
    url:
      "http://www.agencesartistiques.com/Fiche-Artiste/552699-theo-courtial.html",
  },
  {
    label: "Quoc DANG Tran",
    url: "https://www.imdb.com/name/nm2898320/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Iris DE Jessey",
    url:
      "http://www.agencesartistiques.com/Fiche-Artiste/732619-iris-de-jessey.html",
  },
  {
    label: "Noé Debre",
    url: "https://www.imdb.com/name/nm4380548/?ref_=fn_al_nm_1",
  },
  {
    label: "Marion Doussot",
    url:
      "http://www.agencesartistiques.com/Fiche-Artiste/556950-marion-doussot.html",
  },
  {
    label: "Fadette Drouard",
    url: "https://www.imdb.com/name/nm8166889/?ref_=fn_al_nm_1",
  },
  {
    label: "Benjamin Dupas",
    url: "https://www.imdb.com/name/nm2783886/?ref_=fn_al_nm_1",
  },
  {
    label: "Camille Fievez",
    url:
      "http://www.agencesartistiques.com/Fiche-Artiste/734624-camille-fievez.html",
  },
  {
    label: "Mathias Gavarry",
    url: "https://www.imdb.com/name/nm2059904/?ref_=fn_al_nm_1",
  },
  {
    label: "Donald Hebert",
    url:
      "http://www.agencesartistiques.com/Fiche-Artiste/739850-donald-hebert.html",
  },
  {
    label: "Fanny Herrero",
    url: "https://www.imdb.com/name/nm2638139/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Marine Josset",
    url:
      "http://www.agencesartistiques.com/Fiche-Artiste/734623-marine-josset.html",
  },
  {
    label: "Julien-Guilhem Lacombe",
    url:
      "http://www.agencesartistiques.com/Fiche-Artiste/607380-julien-guilhem-lacombe.html",
  },
  {
    label: "Lily Lambert",
    url: "https://www.imdb.com/name/nm9440688/?ref_=ttfc_fc_wr9",
  },
  {
    label: "Nicolas Laquerriere",
    url:
      "http://www.agencesartistiques.com/Fiche-Artiste/711788-nicolas-laquerriere.html",
  },
  {
    label: "Maria Leite",
    url: "https://www.imdb.com/name/nm3473975/?ref_=fn_al_nm_1",
  },
  {
    label: "Nicolas Lemoine",
    url:
      "http://www.agencesartistiques.com/Fiche-Artiste/728192-nicolas-lemoine.html",
  },
  {
    label: "Linda Lô",
    url: "http://www.agencesartistiques.com/Fiche-Artiste/697343-linda-lo.html",
  },
  {
    label: "Laurent Lunetta",
    url:
      "http://www.agencesartistiques.com/Fiche-Artiste/553519-laurent-lunetta.html",
  },
  {
    label: "Gaëlle Mace",
    url:
      "http://www.agencesartistiques.com/Fiche-Artiste/556963-gaelle-mace.html",
  },
  {
    label: "Paul Madillo",
    url:
      "http://www.agencesartistiques.com/Fiche-Artiste/709101-paul-madillo.html",
  },
  {
    label: "Nathalie Najem",
    url: "https://www.imdb.com/name/nm1493704/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Catherine Paille",
    url:
      "http://www.agencesartistiques.com/Fiche-Artiste/619906-catherine-paille.html",
  },
  {
    label: "Nicolas Peufaillit",
    url: "https://www.imdb.com/name/nm2425150/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Franck Philippon",
    url: "https://www.imdb.com/name/nm0680039/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Gauthier Plancquaert",
    url:
      "http://www.agencesartistiques.com/Fiche-Artiste/740200-gauthier-plancquaert.html",
  },
  {
    label: "Emma Sandona",
    url:
      "http://www.agencesartistiques.com/Fiche-Artiste/728131-emma-sandona.html",
  },
  {
    label: "Charlotte Sanson",
    url: "https://www.imdb.com/name/nm6331976/?ref_=fn_al_nm_1",
  },
  {
    label: "Queenie Tassel",
    url:
      "http://www.agencesartistiques.com/Fiche-Artiste/737432-queenie-tassell.html",
  },
  {
    label: "Marie Enthoven",
    url: "https://www.imdb.com/name/nm0258174/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Benoit Marchisio",
    url: "https://www.imdb.com/name/nm11322028/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Quentin Zuttion",
    url: "https://www.imdb.com/name/nm11423393/?ref_=fn_al_nm_1",
  },
  {
    label: "Fabien Nury",
    url: "https://www.imdb.com/name/nm1942997/?ref_=fn_al_nm_1",
  },
  {
    label: "Andrew Bampfield",
    url: "https://www.imdb.com/name/nm1240855/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Chloé Marçais",
    url: "https://www.imdb.com/name/nm1924009/",
  },
  {
    label: "Julien Lambroschini",
    url:
      "http://www.agencesartistiques.com/Fiche-Artiste/750224-julien-lambroschini.html",
  },
  {
    label: "Valentine Monteil",
    url: "https://www.imdb.com/name/nm12460185/?ref_=fn_al_nm_1",
  },
  {
    label: "Nicolas Mathieu",
    url: "https://fr.wikipedia.org/wiki/Nicolas_Mathieu_(écrivain)",
  },
  {
    label: "Maxime Calligaro",
    url: "https://www.imdb.com/name/nm11087265/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Laure DE Colbert",
    url:
      "https://www.agencesartistiques.com/Fiche-Artiste/757556-laure-de-colbert.html",
  },
  {
    label: "Zander Coté",
    url: "https://www.imdb.com/name/nm11112102/?ref_=nv_sr_srsg_0",
  },
  {
    label: "Baptiste Fillon",
    url:
      "https://www.agencesartistiques.com/Fiche-Artiste/757560-baptiste-fillon.html",
  },
  {
    label: "Homeïda Behi",
    url: "https://www.unifrance.org/annuaires/personne/373153/homeida-behi",
  },
  {
    label: "Tarek Haoudy",
    url:
      "https://www.agencesartistiques.com/Fiche-Artiste/758056-tarek-haoudy.html",
  },

  {
    label: "Timothée Prudhomme",
    url:
      "https://www.agencesartistiques.com/Fiche-Artiste/758225-timothee-prudhomme.html",
  },
  {
    label: "Olivier Bouchara",
    url: "http://olivierbouchara.com/bio/",
  },
  {
    label: "Xavier Dorison",
    url: "https://fr.wikipedia.org/wiki/Xavier_Dorison",
  },
  {
    label: "Laure Mentzel",
    url: "https://www.imdb.com/name/nm12045514/?ref_=fn_al_nm_1",
  },
  {
    label: "Antoine Derroja",
    url:
      "https://www.agencesartistiques.com/Fiche-Artiste/761841-antoine-derroja.html",
  },
  {
    label: "Kensley Jules",
    url:
      "https://www.agencesartistiques.com/Fiche-Artiste/762397-kensley-jules.html",
  },
  {
    label: "Manon David ",
    url:
      "https://www.agencesartistiques.com/Fiche-Artiste/762404-manon-david.html",
  },
  {
    label: "Emma Degoutte",
    url:
      "https://www.agencesartistiques.com/Fiche-Artiste/762405-emma-degoutte.html",
  },
  {
    label: "Anna WAJSBROT",
    url:
      "https://www.agencesartistiques.com/Fiche-Artiste/765576-anna-wajsbrot.html",
  },
  {
    label: "Ambroise RATEAU",
    url:
      "https://www.agencesartistiques.com/Fiche-Artiste/765581-ambroise-rateau.html",
  },
  {
    label: "Franck YAYA",
    url:
      "https://www.agencesartistiques.com/Fiche-Artiste/765577-franck-yaya.htmlOK",
  },
  {
    label: "Claire SAINT PIERRE",
    url:
      "https://www.agencesartistiques.com/Fiche-Artiste/765583-claire-saint-pierre.html",
  },
  {
    label: "Thomas KEUMURIAN",
    url:
      "https://www.agencesartistiques.com/Fiche-Artiste/765585-thomas-keumurian.html",
  },
  {
    label: "Anne-Lise RIVOIRE",
    url:
      "https://www.agencesartistiques.com/Fiche-Artiste/765509-anne-lise-rivoire.html",
  },
  {
    label: "Amina Damerdji",
    url:
      "https://www.agencesartistiques.com/Fiche-Artiste/765607-amina-damerdji.html",
  },
  {
    label: "Augustin SHACKELPOLOULOS",
    url:
      "https://www.imdb.com/name/nm10422040/?ref_=nv_sr_srsg_0_tt_1_nm_7_q_augustin%2520sha",
  },
  {
    label: "Sacha BEHAR",
    url: "https://www.imdb.com/name/nm8496364/?ref_=tt_cl_t_1",
  },

  {
    label: "Adrien Rode",
    url:
      "https://www.agencesartistiques.com/Fiche-Artiste/768764-adrien-rode.html",
  },
  {
    label: "Laura Lambert",
    url:
      "https://www.agencesartistiques.com/Fiche-Artiste/768323-Laura-Lambert.html",
  },
  {
    label: "Honorine Crosnier",
    url:
      "https://www.imdb.com/name/nm9776608/?ref_=nv_sr_srsg_0_tt_0_nm_1_q_Honorine%2520Crosnier",
  },
  {
    label: "Guillaume Pouget Abadie",
    url:
      "https://www.imdb.com/name/nm14833604/?ref_=nv_sr_srsg_0_tt_0_nm_1_q_Guillaume%2520Pouget%2520Abadie",
  },
  {
    label: "Anna Ciennik",
    url:
      "https://www.agencesartistiques.com/Fiche-Artiste/768767-anna-ciennik.html",
  },
  {
    label: "Anthony Martin",
    url:
      "https://www.allocine.fr/personne/fichepersonne_gen_cpersonne=638842.html",
  },
  {
    label: "Simon Riaux",
    url:
      "https://www.imdb.com/name/nm5654151/?ref_=nv_sr_srsg_0_tt_0_nm_7_q_simon%2520riaux",
  },
  {
    label: "Rodolphe Bouquet-Populus",
    url:
      "https://www.agencesartistiques.com/Fiche-Artiste/768827-rodolphe-bouquet-populus.html",
  },
  {
    label: "Pauline Baduel",
    url:
      "https://www.agencesartistiques.com/Fiche-Artiste/768823-pauline-baduel.html",
  },
  {
    label: "Lucy Wadham",
    url: "https://fr.wikipedia.org/wiki/Lucy_Wadham",
  },
].sort(sortByName)

const ScreenWriters = (): JSX.Element => {
  const containerRef = useRef(null)
  const { t } = useTranslation()

  useSkewOnScroll(containerRef)

  return (
    <>
      <SEO title={t("screenwriters")} />
      <div ref={containerRef} style={{ willChange: "transform" }}>
        <ListSection
          list={SCREENWRITERS}
          id="screenwriters"
          key="screenwriters"
        />
      </div>
    </>
  )
}

export default ScreenWriters
